module.exports = [{
      plugin: require('../node_modules/gatsby-omni-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"enableListener":true,"preconnect":["https://fonts.googleapis.com","https://fonts.gstatic.com"],"web":[{"name":"Merriweather Sans","file":"https://fonts.googleapis.com/css?family=Merriweather+Sans:400,700"},{"name":"Merriweather","file":"https://fonts.googleapis.com/css?family=Merriweather:400,300,300italic,400italic,700,700italic"}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"G-HS0WDJ8092","head":false,"pageTransitionDelay":0,"anonymize":false,"respectDNT":false,"exclude":[],"enableWebVitalsTracking":false},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
